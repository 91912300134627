import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HappyReturnsComponent } from './happy-returns/happy-returns.component';
import { HappyReturnsLandingComponent } from './happy-returns-landing/happy-returns-landing.component';
import { HappyReturnsRoutingModule } from './happy-returns-routing.module';
import { HappyReturnsLayoutModule } from 'src/app/shared/components/happy-returns-layout/happy-returns-layout.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslatePipe } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import * as ENV from '../../../environments/environment';
import { EmailAddressVerificationModule } from 'src/app/shared/components/email-address-verification/email-address-verification.module';

import { HrFileClaimComponent } from './hr-file-claim/hr-file-claim.component';
import { HrClaimsComponent } from './hr-claims/hr-claims.component';
import { HrClaimListComponent } from './hr-claim-list/hr-claim-list.component';
import { HrMessagesComponent } from './hr-messages/hr-messages.component';
import { HrFaqComponent } from './hr-faq/hr-faq.component';

import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { ClaimsModule } from '../claims/claims.module';
import { HrClaimDetailComponent } from './hr-claim-detail/hr-claim-detail.component';
import { HrNewClaimComponent } from './hr-new-claim/hr-new-claim.component';
import { HappyReturnsStepperModule } from 'src/app/shared/components/happy-returns-stepper/happy-returns-stepper.module';
import { HrTermsComponent } from './components/ht-terms/ht-terms.component';
import { HRAuthGuardService } from 'src/app/core/services/hr-auth-guard.service';
import { HrContactComponent } from './components/hr-contact/hr-contact.component';

@NgModule({
  declarations: [
    HappyReturnsComponent,
    HappyReturnsLandingComponent,
    HrFileClaimComponent,
    HrClaimsComponent,
    HrClaimListComponent,
    HrMessagesComponent,
    HrFaqComponent,
    HrClaimDetailComponent,
    HrNewClaimComponent,
    HrTermsComponent,
    HrContactComponent
  ],
  imports: [
    CommonModule,
    HappyReturnsRoutingModule,
    HappyReturnsLayoutModule,
    EmailAddressVerificationModule,
    SharedModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    ClaimsModule,
    HappyReturnsStepperModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: ENV.environment.recaptcha.siteKey } as RecaptchaSettings
    },
    TranslatePipe,
    HRAuthGuardService
  ]
})
export class HappyReturnsModule { }
