import { Component, OnInit, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './../../../../services/user.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';

@Component({
  selector: 'upsc-help',
  templateUrl: './claim-help.component.html',
  styleUrls: ['./claim-help.component.scss']
})
export class ClaimHelpComponent implements OnInit {
  @HostBinding('class') public hostClass = 'claim-help';
  isGuestUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  public isHappyReturns: boolean = false;
  constructor(
    private titleService: Title,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private taggingService: TaggingService,
    private hpService: HappyReturnsService
  ) {
      this.isCanadaUser = this.userService.isCanadaUser();
      this.isUkUser = this.userService.isUkUser();
      this.isUsaUser = this.userService.isUsaUser();
      this.isGermanyUser = this.userService.isGermanyUser();
      this.isFranceUser = this.userService.isFranceUser();
      this.isItalyUser = this.userService.isItalyUser(); 
    }
  
  ngOnInit() {
    this.titleService.setTitle(this.translateService.instant('app.Settitle.ups_capital_claims'));
    this.isGuestUser = this.userService.isGuestUser();
    this.taggingService.view();



    try {
      let happyReturnsInfo = this.hpService.getHappyReturnsInfo();
      if (happyReturnsInfo !== null && happyReturnsInfo.partnerName === 'happy-returns') {
        this.isHappyReturns = true;
      }
    } catch {
      this.isHappyReturns = false;
    }  
  }

}


