<form [formGroup]="formGroup" (ngSubmit)="onSubmit($event, formGroup.value)">
    <div>
        <div>
            <div class="file-stepper d-flex flex-column mx-auto">
                <div class="stepper-header" *ngIf="currentStep > 0 && currentStep <=4">{{ currentStepText }}</div>

                <div class="labelMobile" *ngIf="currentStep > 0 && currentStep <=4">
                    <mat-icon class="back-icon" (click)="setCurrentStepMobile(currentStep-1)">chevron_left</mat-icon>
                    {{currentStepText}}
                </div>

                <upsc-happy-returns-stepper [steps]="steps" [currentStep]="currentStep" (stepEvent)="stepEvent($event)"
                    *ngIf="currentStep > 0 && currentStep <=4"></upsc-happy-returns-stepper>
                <div class="stepper-description" *ngIf="currentStep===1">
                    Please confirm the details below match the shipment for which you are filing a claim. If the
                    information is incorrect, click “Cancel” to go back and re-enter the correct Happy Returns ID.
                </div>
                <div class="stepper-description" *ngIf="currentStep===2">
                    Please provide additional details about your lost package.
                </div>
                <div class="stepper-description" *ngIf="currentStep===3">
                    Please tell us who should receive payment for this claim.
                </div>
                <div class="stepper-description" *ngIf="currentStep===4">
                    You are almost done! Please verify your claim details below and click Submit.
                </div>
            </div>
        </div>
        <div class="claim-sections" *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="shipmentTemplate"></ng-container>
        </div>
        <div class="claim-sections" *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="claimDetailTemplate"></ng-container>
        </div>
        <div class="claim-sections" *ngIf="currentStep === 3">
            <ng-container *ngTemplateOutlet="contactInfoTemplate"></ng-container>
        </div>
        <div class="claim-sections" *ngIf="currentStep === 4">
            <ng-container *ngTemplateOutlet="reviewTemplate"></ng-container>
        </div>
        <div class="claim-sections" *ngIf="currentStep === 5">
            <ng-container *ngTemplateOutlet="successClaimTemplate"></ng-container>
        </div>
    </div>
</form>

<ng-template #shipmentTemplate>
    <div class="claim-section">
        <form [formGroup]="shipmentDetailFormGroup" class="claim-detail">
            <div class="title-input">Shipment Detail&nbsp;<mat-icon>edit</mat-icon></div>
            <div class="section">
                <div class="subsection">
                    <mat-form-field>
                        <input matInput required formControlName="happyReturnsId" placeholder="Happy Returns Id"
                            (keydown.enter)="next();">
                        <mat-error *ngIf="shipmentDetailFormGroup.get('happyReturnsId').errors">
                            Happy Returns Id Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="error mt-2" *ngIf="systemError">
                {{'common.systemError' | translate }}
            </div>
            <div class="error mt-2" *ngIf="duplicateTrackingNumber">
                A claim has already been filed using this tracking number.
            </div>
            <div class="error mt-2" *ngIf="trackingNumberNotFound">
                Oops! Shipment is not covered under your shipping protection solution, please file claim directly with
                Carrier.
              </div>
            <div class="warning" *ngIf="isInvalidPolicyNumber">
                <mat-icon>warning</mat-icon>
                <span class="text">
                    Your email address is not set up for filing claims. Can we <a class="link"
                        href="mailto:insureshieldsupport@ups.com">help</a>?
                </span>
            </div>
            <div class="button-container">
                <button class="upsc-button-white" (click)="cancel();">
                    <div class="btn-text-icon">
                        <mat-icon>chevron_left</mat-icon>
                        <div class="text">Cancel</div>
                    </div>
                </button>
                <button class="upsc-button" (click)="next();" *ngIf="!loading">
                    <div class="btn-text-icon">
                        <div class="text">Next</div><mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
                <button class="upsc-button-white" *ngIf="loading">
                    <div class="btn-text-icon">
                        <div class="text">Next</div>
                        <div class="spinner-border"></div>
                    </div>
                </button>
            </div>

        </form>
    </div>
</ng-template>

<ng-template #claimDetailTemplate>
    <div class="claim-section">
        <form [formGroup]="claimDetailFormGroup" class="claim-detail">
            <div class="title-input">Product Category</div>
            <div class="section">
                <div class="subsection">
                    <mat-form-field>
                        <input matInput required formControlName="category" placeholder="Product Category">
                        <mat-error *ngIf="claimDetailFormGroup.get('category').errors">
                            Category Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-input">Detailed Merchandise Description</div>
            <div class="section">
                <div class="subsection">
                    <mat-form-field>
                        <input matInput required formControlName="merchandiseDescription"
                            placeholder="Detailed Merchandise Description">
                        <mat-error *ngIf="claimDetailFormGroup.get('merchandiseDescription').errors">
                            Category Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="title-input">How much is the claim?*</div>
            <div class="section">
                <div class="subsubsection-usd">
                    <mat-form-field style="width: 80px;">
                        <input matInput required formControlName="currency" placeholder="Currency">
                        <mat-error *ngIf="claimDetailFormGroup.get('currency').errors">
                            Currency Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="subsection">
                    <mat-form-field>
                        <input matInput required formControlName="merchandiseAmount" appDecimalFieldMask
                            placeholder="Merchandise Amount">
                        <mat-error *ngIf="claimDetailFormGroup.get('merchandiseAmount').errors">
                            Merchandise Amount Required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="button-container">
                <button class="upsc-button-white" (click)="goToStep(1);">
                    <div class="btn-text-icon">
                        <mat-icon>chevron_left</mat-icon>
                        <div class="text">Back</div>
                    </div>
                </button>
                <button class="upsc-button" (click)="saveClaim2()" *ngIf="!continuing">
                    <div class="btn-text-icon">
                        <div class="text">Next</div><mat-icon>chevron_right</mat-icon>
                    </div>
                </button>

                <button class="upsc-button-white" *ngIf="continuing">
                    <div class="btn-text-icon">
                        <div class="text">Next</div>
                        <div class="spinner-border"></div>
                    </div>
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #contactInfoTemplate>
    <form [formGroup]="contactInfoFormGroup" class="white-on-gray">
        <div class="header">
            <div class="title">
                <div class="text">
                    <div class="title-bold">
                        {{ 'claims.edit.claimrecipient.verify' | translate }}
                    </div>
                </div>
            </div>
            <div class="subtitle my-3">{{ 'claims.edit.claimrecipient.subtitle' | translate }}</div>
        </div>
        <form [formGroup]="whoToContactFormGroup">
            <div class="section d-flex flex-column">
                <mat-radio-group formControlName="option" class="d-flex flex-column">
                    <mat-radio-button [value]="'same'" (change)="contactVerificationChange('same')">{{
                        'claims.edit.claimrecipient.option1' | translate }}</mat-radio-button>
                    <mat-radio-button [value]="'different'" (change)="contactVerificationChange('different')">{{
                        'claims.edit.claimrecipient.option2' | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-error
                    *ngIf="whoToContactFormGroup.controls.option.errors && whoToContactFormGroup.controls.option.touched">
                    <div *ngIf="whoToContactFormGroup.controls.option.errors.required">
                        {{ 'claims.edit.claimrecipient.errors.whoToContact' | translate }}
                    </div>
                </mat-error>
            </div>
        </form>
        <form [formGroup]="differentContactFormGroup" *ngIf="whoToContactFormGroup.get('option').value == 'different'">
            <div class="section mt-3">
                <div class="subsection d-flex flex-wrap justify-content-between">
                    <div class="cluster full">
                        <mat-form-field class="width-100">
                            <input matInput formControlName="name" maxlength="30"
                                placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.name' | translate }}">
                            <mat-error *ngIf="differentContactFormGroup.get('name').errors">
                                <div *ngIf="differentContactFormGroup.get('name').errors['required']">
                                    {{ 'claims.edit.claimrecipient.errors.name' | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="cluster">
                        <mat-form-field class="width-100">
                            <input matInput formControlName="email" maxlength="60"
                                placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.email' | translate }}">
                            <mat-error *ngIf="differentContactFormGroup.get('email').errors">
                                <div *ngIf="differentContactFormGroup.get('email').errors['required']">
                                    {{ 'claims.edit.claimrecipient.errors.email' | translate }}
                                </div>
                                <div *ngIf="differentContactFormGroup.get('email').errors['email']">
                                    {{
                                    'claims.edit.contactInfo.contactInformation.contactForm.emailAddress.errors.valid'
                                    | translate }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="cluster">
                        <mat-form-field class="width-100">
                            <input matInput formControlName="phone" upscPhoneNumber minlength="12" maxlength="12"
                                placeholder="{{ 'claims.edit.contactInfo.tupss.placeholders.phone' | translate }}">
                            <mat-error *ngIf="differentContactFormGroup.get('phone').errors">
                                <div *ngIf="differentContactFormGroup.get('phone').errors['required']">
                                    {{ 'claims.edit.claimrecipient.errors.phn' | translate }}
                                </div>
                                <div
                                    *ngIf="differentContactFormGroup.get('phone').errors['pattern'] || differentContactFormGroup.get('phone').errors['minlength']">
                                    {{
                                    'claims.edit.contactInfo.contactInformation.contactForm.phoneNumber.errors.invalid'
                                    | translate
                                    }}
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
        <br>
        <div class="header">
            <div class="title">
                <div class="text">
                    <div class="title-bold">
                        Claim Payment Recipient
                    </div>
                </div>
            </div>
        </div>
        <div class="recipient">
            <div class="error" *ngIf="getPayeeDetailError">
                There was an error retrieving your Claim Payment Recipient information.
            </div>
            <div class="subsection-text break">{{ contactInfoFormGroup.controls.name.value }}</div>
            <div class="subsection-text break">{{ contactInfoFormGroup.controls.email.value }}</div>
            <div class="subsection-text break">{{ contactInfoFormGroup.controls.phone.value | phoneNumber}}</div>
            <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine1.value }}</div>
            <div class="subsection-text break">{{ contactInfoFormGroup.controls.addressLine2.value }}</div>
            <div class="subsection-text">{{ contactInfoFormGroup.controls.city.value }}, {{
                contactInfoFormGroup.controls.state.value }}, {{ contactInfoFormGroup.controls.zip.value }}</div>
        </div>
        <div class="button-container">
            <button class="upsc-button-white" (click)="goToStep(2);">
                <div class="btn-text-icon">
                    <mat-icon>chevron_left</mat-icon>
                    <div class="text">Back</div>
                </div>
            </button>
            <button class="upsc-button" (click)="justContinue()">
                <div class="btn-text-icon">
                    <div class="text">Next</div><mat-icon>chevron_right</mat-icon>
                </div>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #reviewTemplate>
    <form [formGroup]="submitClaimFormGroup" class="white-on-gray">
        <div class="review-template">
            <div class="review-info-box">
                <div class="review-info">
                    <div class="review-header">
                        <span class="text">Shipping Details</span>
                        <span class="edit" (click)="goToStep(1);">Edit</span>
                        <mat-icon (click)="goToStep(1);">edit</mat-icon>
                    </div>
                    <div class="review-content">
                        <div class="text">Happy Returns ID</div>
                        <div class="sub-text">{{ claim.trackingNumber_Ext }}</div>
                    </div>
                </div>
                <div class="review-info">
                    <div class="review-header">
                        <span class="text">Claim Detail Review</span>
                        <span class="edit" (click)="goToStep(2);">Edit</span>
                        <mat-icon (click)="goToStep(2);">edit</mat-icon>
                    </div>
                    <div class="review-content">
                        <div class="text">Product Category</div>
                        <div class="sub-text">{{ claim.productCategory_Ext }}</div>
                        <div class="text">Merchandise Description</div>
                        <div class="sub-text">{{ claim.shippingDetailsDTO.merchandiseDescription }}</div>
                        <div class="text">Claim Amount</div>
                        <div class="sub-text">{{ claim.shippingDetailsDTO.merchandiseValue }}</div>
                    </div>
                </div>
                <div class="review-info">
                    <div class="review-header">
                        <span class="text">Contact Information</span>
                        <span class="edit" (click)="goToStep(3);">Edit</span>
                        <mat-icon (click)="goToStep(3);">edit</mat-icon>
                    </div>
                    <div class="review-content">
                        <div class="text">Contact Details</div>
                        <div class="sub-text">{{ claim.mainContact.firstName}} {{ claim.mainContact.lastName }}</div>
                        <div class="sub-text">{{ claim.mainContact.workNumber | phoneNumber }}</div>
                        <div class="sub-text">{{ claim.mainContact.emailAddress1 }}</div>
                    </div>
                </div>
            </div>
            <div class="review-checkbox">
                <mat-checkbox formControlName="checkboxFormControl"
                    class="d-block blue-background-checkbox-rounded size-30 claim-agreement-layout"
                    (change)="showChecked($event)">
                    I accept the <span class="link">Terms of Service</span>
                </mat-checkbox>

            </div>
            <div class="button-container">
                <button class="upsc-button-white" (click)="goToStep(3);">
                    <div class="btn-text-icon">
                        <mat-icon>chevron_left</mat-icon>
                        <div class="text">Back</div>
                    </div>
                </button>
                <button class="upsc-button" (click)="submitClaim($event)" [disabled]="!termsAccept">
                    <div class="btn-text-icon">
                        <div class="text">Submit</div><mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #successClaimTemplate>
    <div class="success-wrapper d-flex flex-column align-items-center">
        <div class="content-wrapper">
            <em class="material-icons upsgreen600 md-96">check_circle</em>
            <div class="title my-choice">
                {{ 'claims.edit.success.titleExclaim' | translate }}
            </div>
            <div class="description1">
                {{ 'claims.edit.success.descr1' | translate }}
                <span class="description1">{{ submittedClaimNumber }}</span>.
            </div>
            <div class="description3">
                {{ 'claims.edit.success.tupssTccpDescr2' | translate }}
                <span>
                    {{ 'claims.edit.success.tupssTccpDescrAllClaims' | translate }}
                    <a (click)="exitToClaimDetails($event)" class="claims-link">{{
                        'claims.edit.success.tupssTccpDescr2Bold1' | translate }}</a>
                    {{ 'claims.edit.success.tupssTccpDescrAllClaimsEnd' | translate }}
                </span>
            </div>
        </div>
    </div>
</ng-template>