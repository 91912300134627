import { Component } from '@angular/core';
import { DDOnBoardingService } from 'src/app/services/dd-onboarding.service';
import { UserService } from 'src/app/services/user.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { VisibilityPopupComponent } from '../dialog/visibility-popup/visibility-popup.component';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/services/policy.service';

@Component({
  selector: 'upsc-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent {
  public isShowFeatures: boolean = false;
  public closeShowFeatures: boolean = false;
  public currentPlan: string = '';
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  policyDetails: any;
  visibilitySettings: any = {};
  constructor(public dialog: MatDialog,
    private router: Router,
    private policyService: PolicyService,
  ) { }

  ngOnInit() {
    this.policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    let changeplan = JSON.parse(sessionStorage.getItem('changeplanSuccess'));
    sessionStorage.removeItem('changeplanSuccess');
    if(changeplan?.statusCode == "200") {
      this.VisibilityApiCall();
    } else {
    let details = JSON.parse(sessionStorage.getItem('existingPlanDetails'));
    this.currentPlan = details?.subscription;
    }
  }

  VisibilityApiCall() {
    let request = {
      policyNumber: this.policyDetails?.policyNumber,
      action: "query"
    }
    this.policyService.ddVisibilityByAction(request).subscribe(
      data => {
        if (data) {
          this.currentPlan = data?.subscription;
        }
      }
    );
  }

  showFeatures() {
    if (this.isShowFeatures) {
      this.isShowFeatures = false;
    } else {
      this.isShowFeatures = true;
    }
  }

  closeFeatures() {
    if (this.isShowFeatures) {
      this.closeShowFeatures = true;
    } else {
      this.closeShowFeatures = false;
    }
  }
  public changeCancelPlan(subscriptionPlan: string) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '800px';
    this.visibilitySettings.action = "changePlan";
    this.visibilitySettings.subscription = subscriptionPlan;
    sessionStorage.setItem('visibilitySettings', JSON.stringify(this.visibilitySettings));
    let dialogRef = this.dialog.open(VisibilityPopupComponent, config);
    dialogRef.afterClosed().subscribe(
      closed => {
        // localStorage.removeItem('selectedRecentInvoices');
        // localStorage.removeItem('selectedpaymenthistory');
      }
    );
  }

  showUp() {
    sessionStorage.setItem("isDDVUserBackButton",'Yes');
    window.scroll(0, 0);
    this.router.navigate(['/dashboard']);
  }
}
