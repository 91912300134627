<div class="claim-section">
    <div class="logohead d-flex justify-content-between align-items-center">
        <div class="ccp-header">
            <div class="d-flex flex-row main-menu ccp" *ngIf="!isMobile">
                <img class="ccp-logo" src='../../../../assets/images/insureshield-shipping-protection-logo.png'>
                <!-- CCP self service portal -->
                <div class="vertical-divider"></div>
                <img class="ccp-logo pic2" src='../../../../assets/images/happy_returns.png'>
                <ul class="navigation-items ccp">
                    <!-- since we don't have pages for FAQs and contact us yet, the claims tab is hardcoded to be active for now (once we have routing for FAQ/contact us, change this) -->
                    <li class="navigation-item ccp" (click)="setHighlghter('claims')"
                        [class.ccp-active]="tabData.isClaimTabActive">
                        <a class="ccp">Claims</a>
                    </li>
                    <li class="navigation-item ccp" (click)="openContactUs()"
                        [class.ccp-active]="tabData.isFaqTabActive">
                        <a class="ccp">Contact Us</a>
                    </li>
                </ul>
            </div>
            <div class="d-flex" *ngIf="isMobile">
                <div class="d-flex flex-row">
                    <img class="ccp-logo mobile"
                        src='../../../../assets/images/insureshield-shipping-protection-logo.png'>
                    <div class="vertical-divider"></div>
                    <img class="ccp-logo mobile pic2" src='../../../../assets/images/happy-returns-mobile.PNG' (click)="showSidebar = !showSidebar">
                </div>
                <!-- <div class="ml-auto">
                    <mat-icon class="menu-format" (click)="showSidebar = !showSidebar">menu</mat-icon>
                </div> -->
            </div>
        </div>
    </div>

    <div class="sidebar-slider" *ngIf="isMobile && showSidebar">
        <div class="sidebar-close" (click)="showSidebar = !showSidebar"><mat-icon>close</mat-icon></div>
        <div class="sidebar-content">
            <a class="sidebar-text" [class.sidebar-active]="true" (click)="showSidebar = !showSidebar">Claims</a>
            <a class="sidebar-text" [class.sidebar-active]="true" (click)="showSidebar = !showSidebar">FAQs</a>
        </div>
    </div>

    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
</div>