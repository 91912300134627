import { Component } from '@angular/core';
import { MatLegacyDialog as MatLgDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-hr-contact',
  templateUrl: './hr-contact.component.html',
  styleUrls: ['./hr-contact.component.scss']
})
export class HrContactComponent {


  constructor(public dialogRef: MatDialogRef<HrContactComponent>) {
  }

  //Close dialog
  public close() {
    this.dialogRef.close('close');
  }

}
