import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { switchMap } from 'rxjs/operators';
import { PolicyService } from 'src/app/services/policy.service';
import { DDOnBoardingService } from 'src/app/services/dd-onboarding.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'upsc-visibility-popup',
  templateUrl: './visibility-popup.component.html',
  styleUrls: ['./visibility-popup.component.scss']
})
export class VisibilityPopupComponent implements OnInit {
  configData;
  constructor(public dialogRef: MatDialogRef<VisibilityPopupComponent>,
    private userService: UserService,
    private loginService: LoginService,
    private policyService: PolicyService,
    private router: Router,) {
    this.policyDetails = this.loginService.getPolicyDetails();
  }

  policyDetails;
  visibilitySettings;
  public isShowFeatures: boolean = false;
  public createPlan: boolean = false;
  public updatePlan: boolean = false;
  public changed: boolean = false;
  public planIsInprogress: boolean = false;
  planType: string = '';
  planAmount: string = '';
  userId: string = '';
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  userPolicy: string = '';
  users: any = [];
  contaxId: string = 'silcustomer';
  public contaxOnBoarding: any = {};
  policy: any = {};
  showBannerOnVisibilityPageOnly: boolean = false;

  errorPage: boolean = false;
  loading: boolean = false;
  termsAndConditionsIsChecked: boolean = false;
  ngOnInit(): void {
    this.userPolicy = this.userService.getUserInfo()?.policyNumber;
    this.visibilitySettings = JSON.parse(sessionStorage.getItem('visibilitySettings'));
    sessionStorage.removeItem('visibilitySettings');

    if (this.visibilitySettings.action == "inProgress") {
      this.planIsInprogress = true;
    }
    else if (this.visibilitySettings.action == "createPlan") {
      this.createPlan = true;
    }
    else {
      this.updatePlan = true;
    }

    if (this.visibilitySettings.subscription == "starter") {
      this.planType = "Starter";
      this.planAmount = "249";
    }
    else if (this.visibilitySettings.subscription == "pro") {
      this.planType = "Pro";
      this.planAmount = "399";
    }
    else {
      this.planType = "Basic";
      this.planAmount = "0";
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public createChangePlan() {
    if (this.visibilitySettings.action == "changePlan") {
      this.updateSubscription();
    }
    else {
      this.createSubscription();
      this.close();
    }
    
  }



  createSubscription() {
    this.pageLoading = true;
    this.systemError = false;
    this.users = [
      // {
      //   email: "jeff@contax.com",
      //   firstName: "Jeff",
      //   lastName: "Smith"
      // },
      // {
      //   email: "jane@contax.com",
      //   firstName: "Jane",
      //   lastName: "Clark"
      // },
    ];

    let payload = {
      policyNumber: this.userPolicy,
      action: "create",
      subscription: this.visibilitySettings.subscription,
      users: this.users,
    }

    this.policyService.ddVisibilityByAction(payload).subscribe(
      data => {
        if (data?.statusCode !=="400") {
          console.log("The subscription Plan:", data);
          this.router.navigateByUrl('/dashboard');
        }
      }, error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }

  updateSubscription() {
    this.pageLoading = true;
    this.systemError = false;
    let existingdetails = JSON.parse(sessionStorage.getItem('existingPlanDetails'));
    let payload1 = {
      policyNumber: this.userPolicy,
      contractID: existingdetails?.contractNo,
      action: "update",
      subscription: this.visibilitySettings.subscription
    }
    this.policyService.ddVisibilityByAction(payload1).subscribe(
      data => {
        if (data?.statusCode == "200" && data?.message == "Subscription update successful") {
          sessionStorage.setItem('changeplanSuccess', JSON.stringify(data));
          this.pageLoading = false;
          this.changed = true;
          this.updatePlan = false;
          this.router.navigate(['/dashboard']);
        }
        else {
          this.pageLoading = false;
          this.systemError = true;
        }
      }, error => {
        this.pageLoading = false;
        this.systemError = true;
      }
    );
  }
}
