import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimService } from 'src/app/services/claim.service';
import { IStep } from 'src/app/shared/components/happy-returns-stepper/models/step.interface';
import { IClaim, emptyClaim } from 'src/app/shared/models/claim.interface';
import { HappyReturnsDataService } from 'src/app/services/happy-returns-data-service';
import * as REGEX from '../../../shared/regex-patterns'
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HrTermsComponent } from '../components/ht-terms/ht-terms.component';

@Component({
  selector: 'upsc-hr-file-claim',
  templateUrl: './hr-file-claim.component.html',
  styleUrls: ['./hr-file-claim.component.scss']
})
export class HrFileClaimComponent {

  formGroup: UntypedFormGroup;
  shipmentDetailFormGroup: UntypedFormGroup;
  claimDetailFormGroup: UntypedFormGroup;
  contactInfoFormGroup: UntypedFormGroup;
  whoToContactFormGroup: UntypedFormGroup;
  differentContactFormGroup: UntypedFormGroup;
  submitClaimFormGroup: UntypedFormGroup;

  currentStep: any = 1;
  currentStepText = '';

  steps: IStep[];

  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }

  policy: any = {};
  userDetails: any = {};

  placeholderPayee: any = '';
  placeholderStatus: any = '';
  placeholderClaimNum: any;
  placeholderPolicyNum: any;

  getPayeeDetailError: boolean = false;
  accountNumberLast4 = "";
  payeePaymentMethod = "ACH";
  groupCode: string = '';
  happyReturnsInfo: any = {};
  public payeeID: string = '';

  dialogRef: MatDialogRef<HrTermsComponent>;

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private claimService: ClaimService,
    private dataService: HappyReturnsDataService,
    private hpService: HappyReturnsService,
    private loginService: LoginService,
    private userService: UserService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {

    this.userDetails = this.userService.getUserInfo();

    this.formGroup = this.formBuilder.group({
      shipmentDetail: this.shipmentDetailFormGroup,
      claimDetail: this.claimDetailFormGroup,
      contactInfo: this.contactInfoFormGroup,
      submitClaim: this.submitClaimFormGroup
    });


    this.shipmentDetailFormGroup = this.formBuilder.group({
      happyReturnsId: ['', Validators.compose([Validators.required])]
    });

    this.claimDetailFormGroup = this.formBuilder.group({
      category: [{ value: 'General Merchandise', disabled: true }, Validators.compose([Validators.required])],
      merchandiseDescription: [{ value: 'General Merchandise', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(210)])],
      currency: [{ value: 'USD', disabled: true }, Validators.compose([Validators.required])],
      merchandiseAmount: [null, Validators.compose([Validators.required])]
    });

    this.contactInfoFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: false }, Validators.compose([Validators.required])],
      email: [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.email])],
      phone: [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)])],
      addressLine1: [{ value: '', disabled: false }, Validators.compose([Validators.required])],
      addressLine2: [{ value: '', disabled: false }],
      city: [{ value: '', disabled: false }, Validators.compose([Validators.required])],
      state: [{ value: '', disabled: false }, Validators.compose([Validators.required])],
      zip: [{ value: '', disabled: false }, Validators.compose([Validators.required, Validators.pattern(REGEX.TUPSS_FLOW_ZIP_PATTERN)])],
      ach: [{ value: 'check', disabled: false }, Validators.compose([Validators.required])]
    });

    this.whoToContactFormGroup = this.formBuilder.group({
      option: [null, Validators.required]
    });

    this.differentContactFormGroup = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(/\d{1,3}/)])],
    });

    this.submitClaimFormGroup = this.formBuilder.group({
      checkboxFormControl: [false, Validators.compose([Validators.required])]
    });

    this.steps = [
      { order: 1, label: 'Shipment Details', isActive: true, labelTop: 'Confirm Shipment Details' },
      { order: 2, label: 'Claim Detail Review', isActive: false, labelTop: 'Claim Detail Review' },
      { order: 3, label: 'Contact Detail', isActive: false, labelTop: 'Verify Contact Information' },
      { order: 4, label: 'Review and Submit', isActive: false, labelTop: 'Review and Submit' }
    ];

    //this.currentStepText = 'Confirm Shipment Details';

    //if there are params in the URL, set them as variables for future use
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.placeholderPayee = params.payeeID;
        this.placeholderStatus = params.status;
      }
    });
  }

  public stepEvent($event) {
    this.loading = false;
    this.continuing = false;
    this.currentStep = $event;
    this.setActiveStep();
  }


  public ngOnInit() {

    this.hpService.setFileTitle('happy-returns');
    this.instantiateEmptyClaimModel();


    window.onpopstate = (event) => {
      if (event.state && event.state.step !== undefined) {
        this.currentStep = event.state.step;
      }
    }

    this.setCurrentStepManually(this.currentStep);
    this.setActiveStep();

    this.happyReturnsInfo = this.hpService.getHappyReturnsInfo();
    if (this.happyReturnsInfo) {
      this.groupCode = this.happyReturnsInfo.groupCode;
    }

    if (sessionStorage.getItem('payeeID') !== null && sessionStorage.getItem('payeeID').toString().length > 1) {
      this.payeeID = sessionStorage.getItem('payeeID').toString();
    }


    //this occurs once user comes back from DPAT with a successful payee creation
    if (this.placeholderStatus == "success") {
      this.populateStep2(); //re-populates form field on step 2 of claims process (only important if they press back button to go back to step 2 and press continue again)
      this.currentStep = 3; //assuming a successful payee creation, we take them to step 3
      this.setCurrentStepManually(this.currentStep);
      this.setActiveStep();
      // here we get payee details
      let request = {
        'payeeID': this.placeholderPayee,
        'policyNumber': this.userDetails?.policyNumber
      }

      sessionStorage.setItem('payeeID', this.placeholderPayee);
      //retrieves the payee details they just created and display it under Claim Payment Recipient on step 3
      this.claimService.getPayeeDetails(request).subscribe(
        data => {
          if (data && data?.success && data?.success.toLowerCase() == 'false') {
            this.getPayeeDetailError = true;
          } else {
            this.getPayeeDetailError = false;
            this.contactInfoFormGroup.controls.name.setValue(data.data.payeeName);
            this.contactInfoFormGroup.controls.phone.setValue(data.data.phoneNumber);
            this.contactInfoFormGroup.controls.email.setValue(data.data.email);
            this.contactInfoFormGroup.controls.addressLine1.setValue(data.data.addressLine1);
            this.contactInfoFormGroup.controls.addressLine2.setValue(data.data.addressLine2);
            this.contactInfoFormGroup.controls.city.setValue(data.data.city);
            this.contactInfoFormGroup.controls.state.setValue(data.data.state);
            this.contactInfoFormGroup.controls.zip.setValue(data.data.zip);
            /*if (data.data.paymentMethod == "ACH") {
              this.accountNumberLast4 = data.data.accountNumber;
              this.payeePaymentMethod = "ACH";
            } else if (data.data.paymentMethod == "Check") {
              this.payeePaymentMethod = "Check";
            } else if (data.data.paymentMethod == "Zelle") {
              this.accountNumberLast4 = data.data.accountNumber;
              this.payeePaymentMethod = "Zelle";
            } else {
              this.payeePaymentMethod = "Check"; //default to the safest option to display as a failsafe, but should never occur
            }*/
          }
        }, error => {
          this.getPayeeDetailError = true;
        }
      );
      //if they do not create a payee from DPAT and navigate back/cancel, take user back to step 2
    } else if (this.placeholderStatus == "back" || this.placeholderStatus == "cancel") {
      this.populateStep2();
      this.currentStep = 2;
    }
  } //End of ngOnInit


  goToStep(step: any) {
    this.currentStep = step;
    this.currentStepText = this.steps.find(x => x.order === step).labelTop;
    this.setCurrentStepManually(this.currentStep);
    this.setActiveStep();
    try {
      this.populateStep2();
    } catch {

    }
  }

  cancel() {
    this.router.navigate([`/happy-returns/claims/new`]);
  }

  next() {
    if (this.shipmentDetailFormGroup.valid) {
      this.getStarted();
    } else {
      this.validateAllFormFields(this.shipmentDetailFormGroup);
    }
    return false;
  }


  dpatRedirection() {
    if (this.currentStep === 2 && this.claimDetailFormGroup.valid) {
      // Redirect to dpat
      console.log("Redirect to dpat");
    } else {
      this.validateAllFormFields(this.claimDetailFormGroup);
    }
  }

  //Used in Stepper component - Use case: User selects green number to go backwards
  setCurrentStepManually(order: number) {
    if (this.currentStep >= order) {
      this.currentStep = order;
      this.setActiveStep();
      this.currentStepText = this.steps.find(x => x.order === order).labelTop;
    }
  }

  setCurrentStepMobile(order: number) {
    if (this.currentStep >= order && this.currentStep > 1 && this.currentStep <= this.steps.length) {
      this.currentStep = order;
      this.setActiveStep();
      this.currentStepText = this.steps.find(x => x.order === order).labelTop;
    }
  }

  private setActiveStep() {
    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );
  }

  onSubmit(event, value) {
    event.preventDefault();
  }

  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  duplicateTrackingNumber: boolean = false;
  policyMatchError: boolean = false;
  shipmentInformationError: boolean = false;
  loading: boolean = false;
  trackingNumberNotFound: boolean = false;
  systemError: boolean = false;
  happyReturnsDeclaredAmountError: boolean = false;
  isInvalidPolicyNumber: boolean = false;


  getStarted() {
    if (this.shipmentDetailFormGroup.valid) {
      this.loading = true;
      this.duplicateTrackingNumber = false;
      this.dataService.deleteChannelPartnerShipmentDetails();
      this.policyMatchError = false;
      this.shipmentInformationError = false;
      this.trackingNumberNotFound = false;
      this.isInvalidPolicyNumber = false;

      this.claimService.getShipmentDetails(this.shipmentDetailFormGroup.controls.happyReturnsId.value).subscribe(
        data => {
          if (data?.TrackingNumberDetails?.IsDuplicateTrackingNumber == true) {
            this.loading = false;
            this.duplicateTrackingNumber = true;
            this.systemError = false;
          } else {
            this.dataService.gatherHappyReturnsShipmentDetails(this.shipmentDetailFormGroup.controls.happyReturnsId.value).subscribe(
              response => {
                if (response?.error == 'happy-returns insured amount error') {
                  this.loading = false;
                  this.happyReturnsDeclaredAmountError = true;
                } else if (response?.error === 'tracking number not found') {
                  this.loading = false;
                  this.trackingNumberNotFound = true;
                } else if (response?.message === 'succeeded') {
                  //  && response.groupCode === this.hpService.getHappyReturnsInfo().groupCode
                  if (response.policyNumber === this.hpService.getHappyReturnsInfo().policyNumber && response.groupCode === this.hpService.getHappyReturnsInfo().groupCode) {
                    this.happyReturnsDeclaredAmountError = false;
                    this.trackingNumberNotFound = false;
                    this.systemError = false;
                    this.isInvalidPolicyNumber = false;
                    this.populateDataForClaimCreation();
                  } else {
                    this.isInvalidPolicyNumber = true;
                    this.loading = false;
                    this.happyReturnsDeclaredAmountError = false;
                    this.trackingNumberNotFound = false;
                    this.systemError = false;
                  }
                } else {
                  this.loading = false;
                  this.systemError = true;
                  this.happyReturnsDeclaredAmountError = false;
                  this.trackingNumberNotFound = false;
                }
              }, error => {
                if (error?.message.toLowerCase() === 'timeout occurred') {
                  this.loading = false;
                  this.systemError = true;
                } else {
                  this.loading = false;
                  this.systemError = true;
                }
              }
            );
          }
        }, error => {
          console.log(error);
          if (error?.message.toLowerCase() === 'timeout occurred') {
            this.loading = false;
            this.systemError = true;
          } else {
            this.loading = false;
            this.systemError = true;
          }
        }
      );
    }
  }

  claim: IClaim = emptyClaim;
  persistPayeeInfo: any = {};

  continuing = false;
  errorNotification = false;
  systemAPIError = false;

  public persistClaimNumber: string = "tempClaimNumber";
  public persistPublicId: string = "";
  public persistExpirationDate: Date; //These dates handle what dates a user is allowed to select from based on their policy number
  public persistEffectiveDate: Date;

  claimStep2: any = {};

  //Everytime user clicks Continue button, saveClaim is called
  //For tupss this is specifically called on what-happened form page.
  public saveClaim2() {
    if (this.claimDetailFormGroup.valid) {
      this.continuing = true;
      this.errorNotification = false;
      this.systemAPIError = false;
      event.preventDefault();

      this.claim = this.dataService._commonClaimsInterface;
      //Need these 4 values prepopulated for the save claim API.
      this.claim.claimNumber = this.persistClaimNumber;
      this.claim.publicID = this.persistPublicId;
      this.claim.lossType = "PR";
      this.claim.lossCause = "loss_Ext";
      //this.claim.groupCode = this.hpService.getHappyReturnsInfo().groupCode;
      this.gatherFormValues();
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      //this.gatherStep2ValuesOnly(); //save step 2 form field data to put into sessionstorage later
      this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          if (data.claimNumber) {
            // store claim info so when dpat navigates back to tupss, we can retrieve this info (it gets lost otherwise)
            sessionStorage.setItem('claim', JSON.stringify(this.claim));
            //sessionStorage.setItem('claimDetails', JSON.stringify(this.claimStep2));
            let formatFileList = [];  //Needed to format the filelist this way because JSON.stringify does not work on deep object structure            
            sessionStorage.setItem('fileList', JSON.stringify(formatFileList));

            var email = JSON.parse(sessionStorage.getItem('happyReturnsInfo')).email;
            //get ccp request to redirect to DPAT
            let request = this.hpService.getDpatRequest(this.policy, email, 'happy-returns', this.groupCode);
            this.claimService.getRedirect(request).subscribe(
              data => {
                window.location.replace(data.data.url);
              }, error => {
                //this.systemErrorDPAT = true;
              }
            );

          }
          else {
            this.systemAPIError = true;
            window.scrollTo(0, 0);
          }
        }, error => {
          this.continuing = false;
          //this.logInterface.routeName = this.router.url;
          //this.logInterface.exceptionMessage = error.toString();
          /*this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => { }
          );*/
        }, () => {
          this.continuing = false;
        }
      );
    } else {
      this.validateAllFormFields(this.claimDetailFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }


  gatherFormValues() {
    //Payee Data
    this.claim.payeeId = this.persistPayeeInfo ? this.persistPayeeInfo.payeeID : '';
    //StartFormGroup
    if (this.shipmentDetailFormGroup.valid) {
      this.claim.trackingNumber_Ext = this.shipmentDetailFormGroup.controls.happyReturnsId.value ? this.shipmentDetailFormGroup.controls.happyReturnsId.value : "";
      this.claim.shippingDetailsDTO.shipmentIdentificationNumber = this.shipmentDetailFormGroup.controls.happyReturnsId.value ? this.shipmentDetailFormGroup.controls.happyReturnsId.value : "";
      this.claim.transportationCarrierId_Ext = "";
      this.claim.shippingDetailsDTO.senderDetails.companyName = "";
      this.claim.shippingDetailsDTO.senderDetails.city = "";
      this.claim.shippingDetailsDTO.senderDetails.state = "NA";
      this.claim.shippingDetailsDTO.senderDetails.zipCode = "";
      this.claim.shippingDetailsDTO.shipperPostalCode = "";
      this.claim.shippingDetailsDTO.senderDetails.country = "";
      this.claim.shippingDetailsDTO.receiverDetails.companyName = "";
      this.claim.shippingDetailsDTO.receiverDetails.city = "";
      this.claim.shippingDetailsDTO.receiverDetails.state = "NA";
      this.claim.shippingDetailsDTO.receiverDetails.zipCode = "";
      this.claim.shippingDetailsDTO.consigneePostalCode = "";
      this.claim.shippingDetailsDTO.receiverDetails.country = "";
      this.claim.shippingDetailsDTO.shipDate = "";

      var partnerShipmentDetails = JSON.parse(sessionStorage.getItem('partnerShipmentDetails'));
      if (partnerShipmentDetails) {
        this.claim.shippingDetailsDTO.shipDate = partnerShipmentDetails.shipDate;
        this.claim.lossDate = partnerShipmentDetails.shipDate;
      }


      this.claim.shippingDetailsDTO.referenceNumber = "";
    }
    //WhatHappenedFormGroup
    if (this.claimDetailFormGroup.valid) {
      this.claim.lossCause = "";
      this.claim.purposeOfShipment = "";
      this.claim.productCategory_Ext = this.claimDetailFormGroup.controls.category.value ? this.claimDetailFormGroup.controls.category.value : "";
      this.claim.shippingDetailsDTO.merchandiseDescription = this.claimDetailFormGroup.controls.merchandiseDescription.value ? this.claimDetailFormGroup.controls.merchandiseDescription.value : "";
      this.claim.upsmerchandise_Ext = "Description of merchandise in detail = " + this.claimDetailFormGroup.controls.merchandiseDescription.value;
      this.claim.shippingDetailsDTO.insuredValueCurrency = 'USD'; //this.currencyKey;
      this.claim.shippingDetailsDTO.merchandiseValue = this.claimDetailFormGroup.controls.merchandiseAmount.value ? parseFloat(this.claimService.formatAmount(this.claimDetailFormGroup.controls.merchandiseAmount.value.toString(), false)).toFixed(2) : "0";
      this.claim.shippingDetailsDTO.verifiedShippingCharge = "0";
      this.claim.shippingDetailsDTO.insuredAmount = this.claimDetailFormGroup.controls.merchandiseAmount.value ? parseFloat(this.claimService.formatAmount(this.claimDetailFormGroup.controls.merchandiseAmount.value.toString(), false)).toFixed(2) : "0";
      this.claim.claimAmount_Ext = this.claimDetailFormGroup.controls.merchandiseAmount.value ? parseFloat(this.claimService.formatAmount(this.claimDetailFormGroup.controls.merchandiseAmount.value.toString(), false)).toFixed(2) : "0";
      this.claim.additionalNotes = ""
      this.claim.customerNotes = ""
      this.claim.quantity = "0"
    }
    //Contact Info Form Group
    if (!this.contactInfoFormGroup.invalid) {
      this.claim.mainContact.firstName = this.contactInfoFormGroup.controls.name.value;
      this.claim.mainContact.workNumber = this.contactInfoFormGroup.controls.phone.value;
      this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.controls.email.value;
      this.mailingInfo.insured = this.contactInfoFormGroup.controls.name.value;
      this.mailingInfo.streetAddress1 = this.contactInfoFormGroup.controls.addressLine1.value;
      this.mailingInfo.streetAddress2 = this.contactInfoFormGroup.controls.addressLine2.value;
      this.mailingInfo.city = this.contactInfoFormGroup.controls.city.value;
      this.mailingInfo.state = this.contactInfoFormGroup.controls.state.value;
      this.mailingInfo.zip = this.contactInfoFormGroup.controls.zip.value;
      this.mailingInfo.country = 'US';

      if (this.differentContactFormGroup.valid) {
        this.claim.mainContact.firstName = this.differentContactFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.differentContactFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.differentContactFormGroup.controls.email.value;
      }
    }

    //Everytime we gather form details we want to update the data service with the new claim model
    this.dataService.updateCommonClaimObject(this.claim);
  }

  // Since we don't do address validation on our end anymore, this is the new function to go from step 3 to step 4 in the flow
  justContinue() {
    if (this.contactInfoFormGroup.invalid || !this.whoToContactFormGroup.valid || (this.whoToContactFormGroup.controls.option.value == 'different' && !this.differentContactFormGroup.valid)) {
      //Some form was invalid.
      this.validateAllFormFields(this.contactInfoFormGroup);
      if (this.whoToContactFormGroup.valid && this.whoToContactFormGroup.controls.option.value == 'different') {
        this.validateAllFormFields(this.differentContactFormGroup);
      }
      this.validateAllFormFields(this.whoToContactFormGroup);
      window.scrollTo(0, 0);
      this.continuing = false;
    } else {
      this.claim = this.dataService._commonClaimsInterface;
      if (this.whoToContactFormGroup.controls.option.value == 'same') { //same as claim payment recipient
        this.claim.mainContact.firstName = this.contactInfoFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.contactInfoFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.controls.email.value;
      } else if (this.whoToContactFormGroup.controls.option.value == 'different') { // i would like to enter a different
        this.claim.mainContact.firstName = this.differentContactFormGroup.controls.name.value;
        this.claim.mainContact.workNumber = this.differentContactFormGroup.controls.phone.value;
        this.claim.mainContact.emailAddress1 = this.differentContactFormGroup.controls.email.value;
      }
      this.dataService.updateCommonClaimObject(this.claim);
      this.currentStep = 4;
      this.setCurrentStepManually(this.currentStep);
      this.setActiveStep();
      this.continuing = false;
    }
    this.continuing = false;
    this.errorNotification = false;

  }

  gatherStep2ValuesOnly() {
    if (this.claimDetailFormGroup.valid) {
      this.claimStep2.lossCause = ''
      this.claimStep2.purposeOfShipment = '';
      this.claimStep2.productCategory_Ext = '';
      this.claimStep2.shippingDetailsDTO = {};
      this.claimStep2.lossDescription = {};
      this.claimStep2.shippingDetailsDTO.merchandiseDescription = this.claimDetailFormGroup.controls.merchandiseDescription.value ? this.claimDetailFormGroup.controls.merchandiseDescription.value : "";
      this.claimStep2.shippingDetailsDTO.insuredValueCurrency = 'USD'; //this.currencyKey;
      this.claimStep2.shippingDetailsDTO.merchandiseValue = this.claimDetailFormGroup.controls.merchandiseAmount.value ? this.claimDetailFormGroup.controls.merchandiseAmount.value.toString() : "0";
      this.claimStep2.shippingDetailsDTO.verifiedShippingCharge = "0"
      this.claimStep2.shippingDetailsDTO.insuredAmount = this.claimDetailFormGroup.controls.merchandiseAmount.value ? parseFloat(this.claimService.formatAmount(this.claimDetailFormGroup.controls.merchandiseAmount.value.toString(), false)).toFixed(2) : "0";
      this.claimStep2.additionalComments = ''
      this.claimStep2.quantity = "0";
    }
  }

  Object = Object;
  instantiateEmptyClaimModel() {
    //Hold policy information in policy variable to use in future
    let tempFix = JSON.parse(sessionStorage.getItem('policyDetails'));
    //upon refreshing page for tfcp/navigating to dpat, we lose the ability to call policyDetails from tupss service
    this.policy = this.loginService.getPolicyDetails();

    if (!this.policy) {  //Doing this check simply stops the process, and it will eventually re-route back to the login page.
      return;
    }

    if (this.Object.keys(this.policy).length != 0) {
      this.claim = emptyClaim;
      this.claim.policy.policyNumber = this.policy?.policyNumber;
      this.claim.policy.effectiveDate = this.policy?.effectiveDate;
      this.claim.policy.expirationDate = this.policy?.expirationDate;
      this.claim.policy.insured = this.policy?.insured;
      this.claim.policy.policyType = this.policy?.policyType.key;
      this.persistEffectiveDate = new Date(this.loginService.getPolicyDetails()?.effectiveDate);
      this.persistExpirationDate = new Date(this.loginService.getPolicyDetails()?.expirationDate);
    }
    if (this.userDetails?.userRoleID == '1') {
      this.claim.userRole = 'ONL-Admin';  //Must have no spaces
    } else if (this.userDetails?.userRoleID == '3') {
      this.claim.userRole = 'ONL-Guest';
    } else if (this.userDetails?.userRoleID == '4') {
      this.claim.userRole = 'ONL-COI';
    }
  }

  retrieveClaimInfo() {
    this.claim = JSON.parse(sessionStorage.getItem('claim'));
  }


  contactVerificationChange(data) {
    if (data === 'same') {
      if (this.differentContactFormGroup !== undefined) {
        this.differentContactFormGroup.reset();
      }
    }
  }


  populateStep2() {
    this.retrieveClaimInfo(); //we stored the entire claim including step 2 whatHappenedFormGroup data into sessionStorage, retrieve it here and re-populate the form on step 2
    // this entire function does not impact the user after successful DPAT payee creation if they just continue with the flow- 
    // this part is important if they press the back button and navigate to DPAT for a second time
    this.persistClaimNumber = this.claim.claimNumber;
    this.persistPublicId = this.claim.publicID;
    this.claimDetailFormGroup.controls.category.setValue(this.claim.productCategory_Ext);
    this.claimDetailFormGroup.controls.merchandiseDescription.setValue(this.claim.shippingDetailsDTO.merchandiseDescription);
    this.claimDetailFormGroup.controls.currency.setValue(this.claim.shippingDetailsDTO.insuredValueCurrency.toUpperCase());
    this.claimDetailFormGroup.controls.merchandiseAmount.setValue(parseFloat(this.claim.shippingDetailsDTO.merchandiseValue).toFixed(2));
    this.shipmentDetailFormGroup.controls.happyReturnsId.setValue(this.claim.trackingNumber_Ext);
  }

  populateDataForClaimCreation() {
    this.continuing = true;
    this.dataService.getPartnerShipmentDetails().subscribe(
      data => {
        if (data == null) {
          return;
        } else {
          this.dataService.populateClaimInterfaceUsingPartnerInfo(data, this.hpService.getHappyReturnsInfo().groupCode);
          this.dataService.formatPayloadBeforeAnyAPIRequest();
          this.continuing = false;
        }
      }, error => {
        this.continuing = false;
        this.systemAPIError = true;
      }
    );
    this.claimsCreate();
  }


  initialClaimCreation: boolean = false;
  claimsCreate() {
    this.continuing = true;
    this.claimService.createClaim(this.dataService._commonClaimsInterface).subscribe(
      response => {
        this.continuing = false;
        if (response.claimNumber) {
          this.persistClaimNumber = response.claimNumber;
          this.persistPublicId = response.publicId;
          this.initialClaimCreation = false;
          this.systemAPIError = false;
          this.currentStep = 2;
          this.loading = false;
          this.goToStep(2);
        } else {
          this.systemAPIError = true;
          this.loading = false;
        }
      }, error => {
        this.continuing = false;
        this.systemAPIError = true;
        this.loading = false;
      }
    );
  }


  public termsAccept: boolean = false;
  openConfirmation(event: any) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    //config.width = '651px';
    this.dialogRef = this.dialog.open(HrTermsComponent, config);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'accept') {
        this.termsAccept = true;
      } else {
        this.termsAccept = false;
        this.submitClaimFormGroup.get("checkboxFormControl").setValue(false);
      }
    });
  }

  showChecked(event: any): void {
    if (event.checked === true) {
      this.openConfirmation(event.checked);
    } else {
      this.termsAccept = false;
    }
  }

  //Send Claim submission email for channel partner portals
  sendClaimSubmissionEmail(claimNumber) {
    //get ccp request to send email for claim submission
    let request = this.hpService.getClaimSubmissionEmailRequest(this.claim.policy.policyNumber, this.claim.mainContact.emailAddress1,
      claimNumber, this.claim.mainContact.firstName, 'happy-returns');
    this.claimService.sendClaimSubmissionEmail(request).subscribe(
      data => { },
      error => { }
    );
  }

  submittedClaimNumber: string = '';
  //Final submission of claim
  public submitClaim(event?) {
    if (this.submitClaimFormGroup.valid) {
      this.errorNotification = false;
      this.continuing = true;
      this.systemAPIError = false;
      event?.preventDefault();

      this.claim = this.dataService._commonClaimsInterface;
      this.claim.payeeId = this.placeholderPayee;
      this.dataService.updateCommonClaimObject(this.claim);
      this.dataService.formatPayloadBeforeAnyAPIRequest();
     
      this.claimService.submitClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.submittedClaimNumber = data.claimNumber;
          if (data.claimNumber) {
            //Send Claim submission email for channel partner portals
            this.sendClaimSubmissionEmail(data.claimNumber);
            sessionStorage.removeItem('claim');
            sessionStorage.removeItem('claimDetails');
            sessionStorage.removeItem('persistIShipData');
            this.goToStep(5);
          } else {
            this.systemAPIError = true;
            window.scrollTo(0, 0);
          }
        }, error => {
          this.continuing = false;
          this.systemAPIError = true;
          window.scrollTo(0, 0);
        }, () => {
          this.continuing = false;
        }
      );

    } else {
      this.validateAllFormFields(this.submitClaimFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  public submitClaimTemp(event?) {
    this.goToStep(5);
  }

  public exitToClaimDetails(event) {
    this.router.navigate([`/happy-returns/claims/detail`], { queryParams: { claimNumber: this.submittedClaimNumber } });
  }


}
