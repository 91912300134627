import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SwitchPolicyEventService {

  private switchPolicySource = new BehaviorSubject<string>('');
  telecast = this.switchPolicySource.asObservable();

  constructor(private userService: UserService,
    private router: Router) { }


  togglePolicy(policyNumber: string, appRoleId: number) {
    this.userService.getPolicySearch(policyNumber).subscribe(
      policydata => {
        let policy = policydata.data;
        if (policy.parentPolicy !== undefined && policy.parentPolicy) {
          appRoleId = 9;
        }
        this.setPermission(appRoleId, policy, policyNumber);
      }, error => {
        console.log(error);
      });
  }

  setPermission(appRoleId: number, policy: any, policyNumber: string) {
    this.userService.getPermissions(appRoleId).subscribe(
      permsdata => {
        this.userService.setUserPermissions(permsdata.data.permissions);
        sessionStorage.setItem('policyDetails', JSON.stringify(policy));
        sessionStorage.setItem('togglePolicy', JSON.stringify(policyNumber));
        sessionStorage.setItem("UserPolicyDetails", "0");
        this.switchPolicySource.next(policyNumber);
        this.redirectTo('claims');
      }, error => {
        console.log(error);
      });
  }

  redirectTo(url: string): void {
    // When skipLocationChange true, navigates without pushing a new state into history.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  } 


  resetPolicySwitch(){
    this.switchPolicySource.next('');
  }

}

