import { Injectable } from '@angular/core';
import { environment as ENV } from '../../environments/environment';
import { ApiEndpoints as API } from '../../config/api-endpoints';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from './user.service';
import { IPolicies, POLICYTYPES } from '../shared/models/policies.interface';
import { emptyIEmail } from '../shared/models/email.interface';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  private options = {
    headers: new HttpHeaders({
      'Content-type': 'application/json'
    })
  }
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private utilityService: UtilityService
  ) { }

  downloadPCDocument(id, policyNumber: string): Observable<any> {
    let options = { 'responseType': 'arraybuffer' as 'json', 'observe': 'response' as 'body' }
    let shopifyUID = this.userService.getshopifyUserObjectUID();
    if (shopifyUID !== null) {
      return this.http.get(`${ENV.baseUrl.api}${API.DOWNLOAD_PC_DOCUMENT}/${id}` + "/policyNum/" + policyNumber + "?" + "objectUID=" + shopifyUID, options);
    }
    else {
      return this.http.get(`${ENV.baseUrl.api}${API.DOWNLOAD_PC_DOCUMENT}/${id}` + "/policyNum/" + policyNumber, options);
    }
  }

  isGWPolicy() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyDetails) {
      return !!policyDetails.accountNumber && policyDetails.accountNumber != '';
    }
    else {
      return true;
    }
  }

  hasInsights() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyDetails?.dashboardInfo) {
      return true;
    }
    else {
      return false;
    }
  }

  hasSmartUpload() {
    return JSON.parse(sessionStorage.getItem('policyDetails'))?.smartUploadInd == true;
  }

  getSmartUploadUrl(request) {
    return this.http.post<{url: string}>(`${ENV.baseUrl.api}${API.SMART_UPLOAD}`, request);
  }

  hasDeliveryDefense() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.ref1 == 'DD'&& JSON.parse(sessionStorage.getItem('policyDetails'))?.ref2) {
      return true;
    } else {
      return false;
    }
  }

  hasShipVision() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.shipVision) {
      return true;
    } else {
      return false;
    }
  }

  isConnectPolicy() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.hasTCCoverage === true || JSON.parse(sessionStorage.getItem('policyDetails'))?.subPolicyType?.toLowerCase() === 'connect' ||
      this.determinePolicyType(JSON.parse(sessionStorage.getItem('policyDetails'))) == POLICYTYPES.TRANSACTIONAL_CARGO) {
      return true;
    } else {
      return false;
    }
  }

  isChannelPartnerPolicy() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.isChannelPartner == true) {
      return true;
    } else {
      return false;
    }
  }

  isTUPSSContractNumber() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.policyNumber?.startsWith('623876-')) {
      return true;
    } else {
      return false;
    }
  }

  isThreeHyphenContractNo() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.policyNumber?.startsWith('3-')) {
      return true;
    } else {
      return false;
    }
  }

  isTwoHyphenContractNo() {
    if (JSON.parse(sessionStorage.getItem('policyDetails'))?.policyNumber?.startsWith('2-')) {
      return true;
    } else {
      return false;
    }
  }



  getDeliveryDefenseUrl(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DELIVERY_DEFENSE}`, request);
  }

  generateDDContractNumber(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.DD_CONTRACT_NUMBER}`, request);
  }

  ddVisibilityContract(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CREATE_VISIBILITY_CONTRACT}`, request);
  }

  ddVisibilityByAction(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CREATE_VISIBILITY_BY_ACTION}`, request);
  }

  ddVisibilityEmail(recipients) {
    this.http.post(`${ENV.baseUrl.api}${API.DD_VISIBILITY_EMAIL}`,
      {
        ...emptyIEmail,
        ...{
          emailFor: 'DDVisibility',
          data: {
            users: recipients
          },
          countryLocale: 'en'
        }
      }, this.options).subscribe(
        data =>
          this.utilityService.uiLog({
            apiEndpoint: API.DD_VISIBILITY_EMAIL,
            exceptionMessage: `DD onboarding email sent for ${JSON.stringify(recipients)}`
          }).subscribe()
        ,
        error => {
          this.utilityService.uiLog({
            apiEndpoint: API.DD_VISIBILITY_EMAIL,
            exceptionMessage: `DD onboarding email failed for ${JSON.stringify(recipients)}`
          }).subscribe()
          }
      );
  }

  updateThirdPartyIndicator(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPDATE_THIRD_PARTY_INDICATOR}`, request);
  }

  hasPolicyDetails() {
    let policyDetails = JSON.parse(sessionStorage.getItem('policyDetails'));
    if (policyDetails?.policyNumber) {
      return true;
    }
    else {
      return false;
    }
  }

  checkCoverage(tn: string) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CHECK_COVERAGE}`, {trackingNumber: tn});
  }

  createPolicy(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CREATE_POLICY}`, request);
  }

  submitPolicy(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SUBMIT_POLICY}`, request);
  }

  addNewPolicy(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.ADD_POLICIES}`, request);
  }

  updatePolicyNickname(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPDATE_NICKNAME}`, request);
  }

  getUserPolicyDetails(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.POLICY_DETAILS}`, request);
  }

  getChildPolicyInfo(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CHILD_POLICY_INFO}`, request);
  }

  getPolicyShipperAccounts(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SHIPPER_ACCOUNTS}`, request);
  }

  //Transactional Parcel = Flexible Parcel = FLEX = InsureShield for UPS Packages
  //OnDemand_Ups (Basically a Transactional Cargo policy, but with a special categorization)
  //Traditional Cargo = Cargo = InsureShield Complete (coverage type = traditional, ref1 should be null)
  //Transactional Cargo = TC = InsureShield Connect (coverage type = transactional, ref1 should have "api" / "smart upload" / "webhooks" / "other") - CAN ALSO JUST BE "hasTCCoverage = true"
  determinePolicyType(policy: IPolicies): POLICYTYPES {
    //Need these fields to determine a policy type
    if (policy && policy.policyType && policy.coverageType && policy.subPolicyType) {
      let policytype = policy.policyType.key?.toLowerCase();
      let coverage = policy.coverageType.toLowerCase();
      let subtype = policy.subPolicyType.toLowerCase();
      let hasTCOverride = policy.hasTCCoverage;

      //TC Override has to be the first if statement - if this is true, it does not matter at all what the rest of the indicators are.
      if (hasTCOverride) {
        return POLICYTYPES.TRANSACTIONAL_CARGO;
      }

      if ((subtype == 'for ups packages' || subtype == 'forupspackage') || (policytype == 'flex')) {
        return POLICYTYPES.TRANSACTIONAL_PARCEL;
      }
      if (subtype == 'ondemand' || subtype == 'ondemand_ups') {
        return POLICYTYPES.ONDEMAND;
      }
      if (coverage == 'traditional' && subtype == 'complete') {
        return POLICYTYPES.TRADITIONAL_CARGO;
      }

      //SMB is currently coming into TC - we need an additional field to be able to tell if SMB
      if (coverage == 'transactional' && subtype == 'connect') {
        return POLICYTYPES.TRANSACTIONAL_CARGO;
      }


      //If it did not match any of the above
      return POLICYTYPES.UNKNOWN;
    }
  }

  getListOfShipperNumbers(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.SHIPPER_AND_GROUP_LIST}`, request);
  }

  getListOfChildPolicies(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CHILD_POLICY_LIST}`, request);
  }

  public checkPolicyExist(request: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_SSP_INFO}`, request, this.options)
  }

  public getPolicyGroupCodeDetails(request: any): Observable<any> {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_POLICY_GROUPCODE}`, request, this.options)
  }


  getIS4UPSQuoteDetails() {
    let conversionInfo = JSON.parse(sessionStorage.getItem('is4upsQuoteDetails'));
    return conversionInfo;
  }

  // update values in IS4UPSAcquisitions table
  updateIS4UPSInfo(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.UPSERT_ACQUISITION}`, request);
  }

  // retrieve values from IS4UPSAcquisitions table
  retrieveIS4UPSInfo(policyNumber) {
    return this.http.get<any>(`${ENV.baseUrl.api}${API.GET_ACQUISITION}/${policyNumber}`, this.options);
  }

  getShipperNumbers(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.GET_SHIPPER_NUMBERS}`, request);
  }

  validateShipperNumber(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.VALIDATE_SHIPPER_NUMBER}`, request);
  }

  validateMultipleShipperNumbers(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.VALIDATE_MULTIPLE_SHIPPER_NUMBER}`, request);
  }

  sendIS4UPSConversionEmail(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.CONVERSION_EMAIL}`, request);
  }
}
