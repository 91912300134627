<div class="supports">
    <mat-icon (click)="close()">close</mat-icon>
    <div class="speak-with-a-license">{{ 'support.subtitle' | translate }}</div>    
    <div class="contact-block d-flex flex-column align-items-center">
        <a (click)="close()"
            href='mailto:insureshieldca@ups.com'
            class="wrap d-flex flex-column align-items-center">
            <img src="../../../../../assets/images/email.PNG" alt="{{ 'billing.method.email' | translate }}">
            <span class="key align-self-center d-none d-block">{{ 'support.email:' | translate }}</span>
            <span class="key-value align-self-center link2 d-none d-block">
                Insureshieldsupport@ups.com <mat-icon>keyboard_arrow_right</mat-icon>
            </span>
        </a>
    </div>   
</div>