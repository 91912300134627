<div class="claim-sections">
    <div class="claim-section file-a-claim-welcome">
        <span class="user-guide">
            <div class="button-frame">
                <a href="https://bit.ly/isonlguide" class="upsc-button" target="_blank" rel="noopener noreferrer">
                    Claims Filing Guide<mat-icon class="icon-key">open_in_new</mat-icon>
                </a>
            </div>
        </span>

        <span class="frame-container">
            <div class="title-frame">
                <div class="file-a-claim-title">How to File a Claim</div>
                <div class="file-a-claim-text">Gather your claim details and let's get started</div>
            </div>
        </span>

        <div *ngIf="!isMobile" class="desktop-row-format">
            <!-- Digital Wallet Flow Desktop (isWalletUser and not HVCM) -->
            <div class="d-flex flex-column">
                <div class="first-three-items">
                    <div class="item-first-three">
                        <div class="green-number">1.</div>
                        <div class="icon-new">
                            <img src="../../../assets/images/happy-returns/payment-recipient.png">
                            <div>Payment Recipient Details</div>
                        </div>
                    </div>
                    <div class="gray-divider"></div>
                    <div class="item-first-three">
                        <div class="green-number">2.</div>
                        <div class="icon-new">
                            <img src="../../../assets/images/happy-returns/shipment-details.png">
                            <div>Shipment Details</div>
                            <div class="bold italicized">&nbsp;</div>
                        </div>
                    </div>
                    <div class="gray-divider"></div>
                    <div class="item-first-three">
                        <div class="green-number">3.</div>
                        <div class="icon-new">
                            <img src="../../../assets/images/happy-returns/contact-info.png">
                            <div>Contact Information</div>
                            <div class="bold italicized">&nbsp;</div>
                        </div>
                    </div>
                    <div class="gray-divider"></div>
                    <div class="item-first-three">
                        <div class="green-number">4.</div>
                        <div class="icon-new">
                            <img src="../../../assets/images/happy-returns/claim-submission.png">
                            <div>Claim Submission</div>
                            <div class="bold italicized">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isMobile" class="mobile-column-format">
            <div class="item">
                <div class="green-number">1.</div>
                <div class="icon">
                    <img src="../../../assets/images/happy-returns/payment-recipient.png">
                    <div>Payment Recipient Details</div>
                </div>
            </div>
            <div class="item">
                <div class="green-number">2.</div>
                <div class="icon">
                    <img src="../../../assets/images/happy-returns/shipment-details.png">
                    <div>Shipment Details</div>
                    <div class="bold italicized">&nbsp;</div>
                </div>
            </div>
            <div class="item">
                <div class="green-number">3.</div>
                <div class="icon">
                    <img src="../../../assets/images/happy-returns/contact-info.png">
                    <div>Contact Information</div>
                    <div class="bold italicized">&nbsp;</div>
                </div>
            </div>
            <div class="item">
                <div class="green-number">4.</div>
                <div class="icon">
                    <img src="../../../assets/images/happy-returns/claim-submission.png">
                    <div>Claim Submission</div>
                    <div class="bold italicized">&nbsp;</div>
                </div>
            </div>
        </div>

        <div class="claim-filer-section">
            <div class="single-claim">
                <div class="claim-box">
                    <span class="image-container"><img
                            src="../../../assets/images/happy-returns/file-single-claim.png"></span>
                    <span class="header">File A Single Claim</span>
                    <span class="para">This option is best for submitting one claim after a loss, damage, or porch
                        piracy.</span>
                    <div class="get-started">
                        <button class="upsc-button" (click)="getStarted();" href="#">{{'claims.edit.navi_isop.started' |
                            translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
                    </div>
                </div>
            </div>
            <div class="multiple-claims">
                <div class="claim-box">
                    <span class="image-container"><img
                            src="../../../assets/images/happy-returns/file-multiple-claims.png"></span>
                    <span class="header">File Multiple Claims</span>
                    <div class="para">
                        <span class="claim">This option is best for batch uploading claims using the </span>
                        <span class="multi-claim" (click)="downloadTemplate()">MultiClaim Submission Form</span>
                    </div>
                    <div class="get-started">
                        <button class="upsc-button" (click)="goToMultiClaim();">{{
                            'claims.edit.navi_isop.started'
                            |
                            translate }}<mat-icon>keyboard_arrow_right</mat-icon></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="claim-footer">
            <div class="claim-footer-container">
                <span class="mat-icon-container"><mat-icon class="icon-key">info</mat-icon></span>
                <span class="text-footer">For missing contents or damage claims, please retain the merchandise and
                    original
                    packaging until the claim is resolved.</span>
            </div>
        </div>

        <div class="error2 mt-2" *ngIf="systemError">
            {{ 'common.systemError' | translate }}
        </div>
    </div>
</div>