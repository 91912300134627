<!-- <div class="px-0 backGroundHeader">
  <upsc-header></upsc-header>
</div> -->
<div class="px-0">
 <!--  <upsc-navigation></upsc-navigation> (click)="closeBanner()" -->
  <upsc-top-navigation></upsc-top-navigation>
  <upsc-switch-policy-notify></upsc-switch-policy-notify>
  <upsc-digital-wallet-banner *ngIf="!isThreeContractNo"></upsc-digital-wallet-banner> <!-- this banner will only appear on the dashboard page -->
  <upsc-is4ups-banner></upsc-is4ups-banner> <!-- this banner will only appear on the dashboard page -->
</div>
<div class="main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<div class="px-0 backGroundFooter">
  <upsc-footer></upsc-footer>
</div>
<!-- For new DD Visibility item -->
<!-- <div>
  <upsc-visibility-agreement-flyout></upsc-visibility-agreement-flyout>
</div> -->
