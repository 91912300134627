import { Component, OnInit } from '@angular/core';
import { PolicyService } from 'src/app/services/policy.service';
import { IPolicies } from 'src/app/shared/models/policies.interface';
import { HostListener } from '@angular/core';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VisibilityPopupComponent } from '../dd-visibility/dialog/visibility-popup/visibility-popup.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

@Component({
  selector: 'upsc-insights-container',
  templateUrl: './insights-container.component.html',
  styleUrls: ['./insights-container.component.scss']
})
export class InsightsContainerComponent implements OnInit {
  //policy: IPolicies;
  //policy: IPolicies;
  public isShowFeatures: boolean = false;
  public pageLoading: boolean = false;
  public systemError: boolean = false;
  visibilitySettings: any = {};
  public currentPlan: string = '';
  // userId: string = '';
  // userPolicy: string = '';
  // users: any = [];
  // contaxId: string = 'silcustomer';
  // public contaxOnBoarding: any = {};
  policy: any = {};

  constructor(
    public dialog: MatDialog,) {
  }


  ngOnInit(): void {}

  public changeCancelPlan(subscriptionPlan: string) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.width = '510px';
    this.visibilitySettings.action = "createPlan";
    this.visibilitySettings.subscription = subscriptionPlan;
    sessionStorage.setItem('visibilitySettings', JSON.stringify(this.visibilitySettings));
    let dialogRef = this.dialog.open(VisibilityPopupComponent, config.data);
    dialogRef.afterClosed().subscribe(
      closed => {
      }
    );
  }

  showFeatures() {
    if (this.isShowFeatures) {
      this.isShowFeatures = false;
    } else {
      this.isShowFeatures = true;
    }
  }

  // All code below is to ensure that the background gradient image stays perfectly aligned no matter the screen size
  //Everything below is for new DD Visibility item
  ngAfterViewInit() {
    this.detectScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.detectScreenSize();
  }
  detectScreenSize(event?) {
    if (window?.innerWidth && window.innerWidth > 1448) {
      if (((window.innerWidth - 1438) / -2 ) > -15) {
        let page = document.getElementById('background') as HTMLLinkElement;
        page.style.marginLeft = "-15px";
        page.style.marginRight = "-15px";
      } else {
        let val = (window.innerWidth - 1438) / -2;
        let val2 = val + "px";
        let page = document.getElementById('background') as HTMLLinkElement;
        page.style.marginLeft = val2;
        page.style.marginRight = val2;
      }   
    } else {
      let page = document.getElementById('background') as HTMLLinkElement;
      page.style.marginLeft = "-15px";
      page.style.marginRight = "-15px";
    }
  }
}
