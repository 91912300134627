//This service is for handling all SSP related data.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IClaim, emptyClaim } from 'src/app/shared/models/claim.interface';
import { environment } from '../../environments/environment';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { IChannelPartnerShipmentDetails, IShippingDetailsAutofill, emptyChannelPartnerShipmentDetails, emptyShippingDetails, emptyShippingDetailsWithErrorResponse, hardCodedChannelPartnerShipmentDetails, hardCodedTrack3ShipmentDetails } from 'src/app/shared/models/shippingDetails.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { TupssService } from '../pages/claims-custom/tupss/services/tupss.service';
import { IShipInterface } from '../pages/claims-custom/tupss/interfaces/claim-data.interface';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HappyReturnsDataService {
  commonClaimsInterface$ = new BehaviorSubject<IClaim>(null);
  _commonClaimsInterface: IClaim;
  shipmentData;
  channelPartnerShipmentDetails$ = new BehaviorSubject<IChannelPartnerShipmentDetails>(null);
  _channelPartnerShipmentDetails: IChannelPartnerShipmentDetails;
  iShipDetails$ = new BehaviorSubject<IShipInterface>(null);
  _iShipDetails: IShipInterface;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.setCommonClaimsInterface(JSON.parse(sessionStorage.getItem('claimModel')));
    this.setPartnerShipmentDetails(JSON.parse(sessionStorage.getItem('partnerShipmentDetails')));
  }

  setPartnerShipmentDetails(shipmentDetails: IChannelPartnerShipmentDetails) {
    this._channelPartnerShipmentDetails = shipmentDetails;
    this.channelPartnerShipmentDetails$.next(shipmentDetails);
    sessionStorage.setItem('partnerShipmentDetails', JSON.stringify(shipmentDetails));
  }

  getPartnerShipmentDetails() {
    return this.channelPartnerShipmentDetails$;
  }

  deleteChannelPartnerShipmentDetails() {
    this.setPartnerShipmentDetails(null);
  }

  //Set service variable & BehaviorSubject
  setCommonClaimsInterface(claimData: IClaim) {
    this._commonClaimsInterface = claimData;
    this.commonClaimsInterface$.next(claimData);
    sessionStorage.setItem('claimModel', JSON.stringify(claimData));
  }

  //Get BehaviorSubject
  getCommonClaimsInterface() {
    return this.commonClaimsInterface$;
  }

  //Set service variable & BehaviorSubject
  setIShipDetails(iShipData: IShipInterface) {
    this._iShipDetails = iShipData;
    this.iShipDetails$.next(iShipData);
  }

  //Get BehaviorSubject
  getIShipDetails() {
    return this.iShipDetails$;
  }

  //get call back url for ccp DPAT redirection  
  getCallBackURL(urlBase: string, partner: string) {
    let callBackURL = `${urlBase}/${partner}/detail`;
    return callBackURL;
  }


  populateClaimInterfaceUsingPartnerInfo(shipmentDetails: IChannelPartnerShipmentDetails, groupCode: string) {
    this.simpleSetup();
    let claimData = {
      shippingDetailsDTO: {
        shipperCompanyName: shipmentDetails.shipperName,
        shipDate: shipmentDetails.shipDate,
        shipperAddressLine: shipmentDetails.originAddress,
        shipperCity: shipmentDetails.originCity,
        shipperStateProvinceCode: shipmentDetails.originState,
        shipperPostalCode: shipmentDetails.originPostalCode,
        shipperCountryCode: shipmentDetails.originCountryCode,
        consigneeAddressLine1: shipmentDetails.destinationAddress,
        consigneeCity: shipmentDetails.destinationCity,
        consigneeStateProvinceCode: shipmentDetails.destinationState,
        consigneePostalCode: shipmentDetails.destinationPostalCode,
        consigneeCountryCode: shipmentDetails.destinationCountryCode,
        senderDetails: {
          companyName: shipmentDetails.shipperName,
          city: shipmentDetails.originCity,
          state: shipmentDetails.originState,
          country: shipmentDetails.originCountryCode,
          zipCode: shipmentDetails.originPostalCode,
          address1: shipmentDetails.originAddress
        },
        receiverDetails: {
          companyName: shipmentDetails.consigneeName,
          city: shipmentDetails.destinationCity,
          state: shipmentDetails.destinationState,
          country: shipmentDetails.destinationCountryCode,
          zipCode: shipmentDetails.destinationPostalCode,
          address1: shipmentDetails.destinationAddress
        },
        originScan: ''
      },
      originScan: '',
      trackingNumber_Ext: shipmentDetails.trackingNumber,
      transportationCarrierId_Ext: shipmentDetails.carrier,
      lossDate: shipmentDetails.shipDate ? shipmentDetails.shipDate : '',
      claimSource: 'CCP4CP-Master',
      groupCode: groupCode
    }

    let claim = _.merge(this._commonClaimsInterface, claimData);
    this.setCommonClaimsInterface(claim);
  }

  //My choice and Tupss need the emptyclaim start. Otherwise they are missing policy object, maincontact object, etc.
  //Normal flow does not need this step.
  simpleSetup() {
    this.updateCommonClaimObject(emptyClaim);
  }

  gatherPartnerShipmentDetails(trackingNumber: string, partnerName: string): Observable<any> {
    let endpoint = `${environment.baseUrl.api}${ApiEndpoints.DATABRICKS_SHIPMENT_DETAILS}`;
    let request = {
      trackingNum: trackingNumber,
      isInXpress: false
    }
    return this.http.post<any>(endpoint, request).pipe(map(
      response => {
        /*if (partnerName === 'happy-returns' && response?.code === 200 && response?.message.toLowerCase() === 'succeeded') {
          if (Number(response.data.insuredValue) < 101) {
            return { error: "happy-returns insured amount error" };
          } else {
            this.setPartnerShipmentDetails(response.data);
            return { message: "succeeded", policyNumber: response.data.policyNumber };
          }
        } else*/
        if (response?.code === 200 && response?.message.toLowerCase() === 'succeeded') {
          this.setPartnerShipmentDetails(response.data);
          return { message: "succeeded", policyNumber: response.data.policyNumber, groupCode: response.data.groupCode };
        } else if (response?.code === 200 && response?.message.toLowerCase() === 'tracking number not found') {
          return { error: "tracking number not found" };
        }
      },
      error => {
        if (error?.code === 500 && error?.message.toLowerCase() === 'timeout occurred') {
          return { errorMessage: "timeout occurred" };
        } else {
          this.setPartnerShipmentDetails(emptyChannelPartnerShipmentDetails);
          return { errorMessage: 'internal error' };
        }
      }
    ));
  }

  gatherHappyReturnsShipmentDetails(trackingNumber: string): Observable<any> {
    let endpoint = `${environment.baseUrl.api}${ApiEndpoints.HAPPY_RETURNS_SHIPMENT_DETAILS}`;;
    let request = {
      trackingNum: trackingNumber
    }

    return this.http.post<any>(endpoint, request).pipe(map(
      response => {
        if (response?.code === 200 && response?.message.toLowerCase() === 'succeeded') {
          this.setPartnerShipmentDetails(response.data);
          return { message: "succeeded", policyNumber: response.data.policyNumber, groupCode: response.data.groupCode };
        } else if (response?.code === 200 && response?.message.toLowerCase() === 'tracking number not found') {
          return { error: "tracking number not found" };
        }
      },
      error => {
        if (error?.code === 500 && error?.message.toLowerCase() === 'timeout occurred') {
          return { errorMessage: "timeout occurred" };
        } else {
          this.setPartnerShipmentDetails(emptyChannelPartnerShipmentDetails);
          return { errorMessage: 'internal error' };
        }
      }
    ));
  }

  //Cleaning up some formats so we are sure that GW won't throw validation errors
  //This should be called before any claim request.
  formatPayloadBeforeAnyAPIRequest() {
    let tempClaim = this._commonClaimsInterface;
    //Remove "currency" from the money amounts. Sometimes would send "10.00 usd" or something like that. Should be "10.00".
    tempClaim.shippingDetailsDTO.verifiedShippingCharge = tempClaim.shippingDetailsDTO.verifiedShippingCharge?.split(" ")[0];
    tempClaim.shippingDetailsDTO.merchandiseValue = tempClaim.shippingDetailsDTO.merchandiseValue?.split(" ")[0];
    tempClaim.shippingDetailsDTO.invoiceAmount = tempClaim.shippingDetailsDTO.invoiceAmount?.split(" ")[0];

    //GW Does not like the extra zero at the end of the dates.
    //Used to be lossDate:"2023-05-03T04:00:00.000Z".
    //Now should be lossDate:"2023-05-03T04:00:00Z".
    let tempLD = tempClaim.lossDate ? new Date(tempClaim.lossDate).toISOString() : '';
    tempClaim.lossDate = tempLD.replace(".000Z", "Z");
    let tempSD = tempClaim.shippingDetailsDTO.shipDate ? new Date(tempClaim.shippingDetailsDTO.shipDate).toISOString() : '';
    tempClaim.shippingDetailsDTO.shipDate = tempSD.replace(".000Z", "Z");

    //This fixes insuredAmount being something like 17.00000000001
    let tempIV = tempClaim.shippingDetailsDTO.insuredAmount;
    if (tempIV) {
      tempIV = Number(tempIV).toFixed(2).toString();
      tempClaim.shippingDetailsDTO.insuredAmount = tempIV;
    }

    //This fixes claimAmount being something like 17.00000000001
    let tempCA = tempClaim.claimAmount_Ext;
    if (tempCA) {
      tempCA = Number(tempCA).toFixed(2).toString();
      tempClaim.claimAmount_Ext = tempCA;
    }

    this.updateCommonClaimObject(tempClaim);
  }

  updateCommonClaimObject(claimData: IClaim) {
    let claim = _.merge(this._commonClaimsInterface, claimData);
    this.setCommonClaimsInterface(claim);
  }
}
