import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabData } from 'src/app/shared/models/tab-data';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { CssThemesService } from 'src/app/services/css-themes.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { HappyReturnsService } from 'src/app/services/happy-returns.service';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HrContactComponent } from '../components/hr-contact/hr-contact.component';


@Component({
  selector: 'upsc-happy-returns',
  templateUrl: './happy-returns.component.html',
  styleUrls: ['./happy-returns.component.scss']
})
export class HappyReturnsComponent {


  partner: string = '';
  tabData: TabData = {};
  showSidebar: boolean = false;
  userDetails: any = {};

  dialogRef: MatDialogRef<HrContactComponent>;

  constructor(private userService: UserService,
    private themeService: CssThemesService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private hpService: HappyReturnsService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit() {
    this.loadStyles();
    this.tabData.isClaimTabActive = true;
    this.translateService.use('en');
  }

  claimsRedirect() {
    try {
      this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      if (this.userDetails && this.userDetails !== null && this.userDetails.isClaimsActive === true) {
        this.router.navigate([`/happy-returns/claims`]);
      } else {
        this.router.navigate([`/happy-returns`]);
      }
    } catch {
      this.router.navigate([`/happy-returns`]);
    }
  }

  setHighlghter(tabName: string) {
    if (tabName === 'contact') {
      this.tabData.isClaimTabActive = false;
      this.tabData.isFaqTabActive = true;
    } else {
      this.tabData.isClaimTabActive = true;
      this.tabData.isFaqTabActive = false;
    }
    this.claimsRedirect();
  }

  loadStyles() {
    this.userService.updateUserInfo({ type: 'happy-returns' });
    this.themeService.loadHappyReturnsStyle();
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  get isMobileSM() {
    return this.breakpointObserver.isMatched('(max-width: 576px)');
  }


  openContactUs() {
    //this.setHighlghter('contact');
    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '500px';
    this.dialogRef = this.dialog.open(HrContactComponent, config);
    this.dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === 'close' || result === undefined) {
        //this.setHighlghter('claims');
      }
    });
  }


}
