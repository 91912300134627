import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import { AccountInfo } from '../shared/models/account-info.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as ENV } from 'src/environments/environment';
import { IPolicies } from '../shared/models/policies.interface';
import { IEmail } from '../shared/models/email.interface';
import { ApiEndpoints as API } from '../../config/api-endpoints';
import { UserService } from './user.service';
import { ILogger } from '../shared/models/logger.interface';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NetworkChangeComponent } from '../shared/dialogs/network-change/network-change.component';
import { Router } from '@angular/router';

//From the shared services folder

export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL
}
@Injectable()
export class UtilityService {
  private breakpointMonitor: BehaviorSubject<number> = new BehaviorSubject<number> (null);
  private windowResize$ = fromEvent(window, 'resize');
  public userDetails: AccountInfo;
  public policyDetails: IPolicies;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router
    ) {
    this.emitScreenSize();
    this.windowResize$
      .subscribe(
        evt => this.emitScreenSize(evt)
      );
    this.userDetails = this.userService.getUserInfo();
    this.policyDetails = this.loginService.getPolicyDetails();
  }

  private emitScreenSize (evt?: any) {
    let windowSize = evt && evt.target && evt.target.innerWidth ? evt.target.innerWidth : window.innerWidth;
    if(windowSize < 576) {
      this.breakpointMonitor.next(SCREEN_SIZE.XS)
    }
    else if(windowSize < 768 ) {
      this.breakpointMonitor.next(SCREEN_SIZE.SM)
    }
    else if(windowSize < 992) {
      this.breakpointMonitor.next(SCREEN_SIZE.MD)
    }
    else if(windowSize < 1200) {
      this.breakpointMonitor.next(SCREEN_SIZE.LG)
    }
    else {
      this.breakpointMonitor.next(SCREEN_SIZE.XL)
    }
  }

  public appsCurrentStep: number;

  public clearSubscriptions(subscriptions: Subscription[]) {
    if (subscriptions) {
      let subInReverse = [...subscriptions].reverse();
      subInReverse.forEach(
        s => {
          if (s && !s.closed) {
            s.unsubscribe();
          }
        }
      )
    }
    subscriptions.fill(null);
  }

  public tooltipPositionWatch (): Observable<string> {
    return this.breakpointMonitor
    .pipe(
      map(
        size => size < SCREEN_SIZE.MD ? 'above' : 'after'
      ),
      distinctUntilChanged()
    )
  }

  public sendContactEmail(email: IEmail) {
    email.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post(`${ENV.baseUrl.api}${API.CONTACT_US}`, email, this.httpOptions);
  }

  public sendGuestFilingEmail(email: IEmail) {
    email.CountryLocale = sessionStorage.getItem('locale');
    return this.http.post(`${ENV.baseUrl.api}${API.GUEST_FILING_CONFIRMATION}`, email, this.httpOptions);
  }

  public uiLog(log: ILogger): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.uilog}`, log, this.httpOptions);
  }

  loginLog(request) {
    return this.http.post<any>(`${ENV.baseUrl.api}${API.LOGIN_LOG}`, request, this.httpOptions);
  }

  // decommissioned as of 4/4/2023
  // tfcpLog(request) {
  //   return this.http.post<any>(`${ENV.baseUrl.api}${API.TFCP_LOG}`, request, this.httpOptions);
  // }

  getTerms(body): Observable<any> {
    return this.http.post(`${ENV.baseUrl.api}${API.GET_TERM}`, body, this.httpOptions);
  }

  networkChangeDetected() {
    if (this.dialog.openDialogs.length == 0) {
      let dialogRef = this.dialog.open(NetworkChangeComponent);
      dialogRef.afterClosed().subscribe(result => {
        this.loginService.removeLocalStorage();
        this.router.navigate(['/login']);
      });
    }
  }

  public popoverPositionWatch (): Observable<string> {
    return this.breakpointMonitor
    .pipe(
      map(
        size => size < SCREEN_SIZE.LG ? 'bottom-right' : 'bottom'
      ),
      distinctUntilChanged()
    )
  }

  getURLWithLocale (url: any): any {
    let locale = sessionStorage.getItem('localefrmURL') ?? sessionStorage.getItem('locale');
    if(!locale || locale.trim() == '' || locale.toLowerCase() == 'en') {
      return url;
    }
    if(typeof url == 'string') {
      return `${url}/${locale}`
    }
    else if(Array.isArray(url)){
      url.push(locale);
      return url;
    }
    else {
      return url;
    }
  }
}
